import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-plans-and-prices',
    templateUrl: './plans-and-prices.component.html',
    styleUrls: ['./plans-and-prices.component.scss']
})
export class PlansAndPricesComponent implements OnInit {
    @Input() public showImages: boolean;

    public cards = [
        {
            title: 'Reserva',
            subtitle: 'Gratis',
            button: {
                text: 'Contrata gratis',
                link: 'seleccion-plan',
                params: { subproduct: 'TUU', qtyproduct: 1, purchaseOrigin: 'reserva' }
            },
            list: [
                {
                    text: '<b>3</b> agendas.',
                    tooltip: 'Corresponden a calendarios independientes que se manejan dentro de una misma cuenta Reserva. Ejemplo: para una barbería, cada agenda debería ir asociada a un barbero.'
                }, {
                    text: 'Reservas <b>ilimitadas.</b>',
                    tooltip: 'Límite máximo 5 reservas en un mismo bloque horario de cada agenda'
                }, {
                    text: '<b>Sitio web de reservas</b> con catálogo de servicios.',
                }, {
                    text: '<b>Personalización de sitio web</b> y correos con tu marca.',
                }, {
                    text: '<b>Recibe pagos online,</b> presencial y por adelantado.',
                }, {
                    text: 'Emisión de <b>boleta y factura electrónica.</b>',
                }, {
                    text: '<b>Historial de clientes</b> e información adicional.',
                }
            ]
        }
    ];

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any
    ) { }


    ngOnInit(): void {
        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 100);
    }

    public track($event: Event){
        traceButton2($event,'navigate')
    }

    public navigateTo(path: string, params: any): void {
        this.router.navigate([path], {
            queryParams: params,
            queryParamsHandling: 'merge'
        })
    }
}
