import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService } from '../../services/url.service';
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { urlMapping } from '../../route-ids';

export interface IPricingCard {
    device: string;
    features: Array<IPricingCardFeature>;
    title: string;
    price: number;
    button: { id: string; text: string; path: string; queryParams?: boolean }
    list: Array<IPricingCardListItem>;
}

export interface IPricingCardListItem {
    icon: string;
    text: string;
}

export interface IPricingCardFeature {
    icon: string;
    name: string;
}

@Component({
    selector: 'app-pricing-card',
    templateUrl: './pricing-card.component.html',
    styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent {
    @Input() public loadingImages: boolean;
    @Input() public boletaRedirect: boolean = false;
    @Input() public data: any;
    @Input() public isDirect: boolean;

    public url: string;
    public buttonId: string;
    public linkId: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private urlService: UrlService
    ) { }

    ngOnInit(): void {
        const urlTree = this.router.parseUrl(this.router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;

        // Verificar si existe el parámetro purchaseOrigin y su valor es reserva
        const queryParams = urlTree.queryParams;
        this.url = (queryParams?.['purchaseOrigin'] === 'reserva')
            ? (queryParams['subproduct'] === 'TUU' ? 'reserva' : 'reserva-plus')
            : this.url;
        this.url = (queryParams?.['purchaseOrigin'] === 'precios')
            ? (queryParams['subproduct'] === 'TUU' ? 'precios' : 'precios-plus')
            : this.url;
        this.buttonId = 't-'+this.url+'-'+this.data.button.id;
        this.linkId = 't-'+this.url+'-';
   
        if(this.isDirect && !this.data.button.path.includes('directa')){
            this.data.button.path = '/directa'+this.data.button.path;
        }
    }

    public navigateTo(path: string, queryParams: boolean, event: Event, addNewParams: boolean = true): void {
        const comision = queryParams ? { comision: true } : null;
        var purchaseOrigin = this.urlService.getValue('purchaseOrigin');
        if (!purchaseOrigin) {
            purchaseOrigin = this.cleanUrl(this.router.url).split('/')[1] || 'home';
        }
        const newParams = addNewParams ? { purchaseOrigin: purchaseOrigin, ...comision } : {};
        traceButton2(event, 'navigate');

        this.router.navigate([path], {
            queryParams: newParams,
            queryParamsHandling: 'merge'
        });
        this.scrollTop();
    }

    private cleanUrl(url: string): string {
        return url.split(/[?#]/)[0];
    }

    public scrollTop(): void {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}