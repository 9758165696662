// import { isPlatformBrowser } from '@angular/common';
import {
    Component, ElementRef,
    Input,
    // Inject,
    // PLATFORM_ID
} from '@angular/core';
// import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../../shared/scrollTo.class';
@Component({
    selector: 'app-reseller-options',
    templateUrl: './reseller-options.component.html',
    styleUrls: ['./reseller-options.component.scss']
})
export class ResellerOptionsComponent extends ScrollToClass {
    @Input() public loadingImages: boolean;

    public isStandartActive = false;

    public listPro = [
        {
            icon: 'icon-sprite-interactive',
            text: '<b>Obtén tu código de revendedor.</b><br>Asegúrate de que tus clientes compren con tu código.'
        },
        {
            icon: 'icon-sprite-percent',
            text: '<b>Gana $20.000 por cada máquina</b> que vendas y <b>0,1% de comisión mensual </b>por las ventas de tus clientes.'
        },
        {
            icon: 'icon-sprite-dollar',
            text: '<b>Recibe tus ganancias</b> al cierre de cada mes.'
        }
    ];

    public listStandart = [
        {
            icon: 'icon-sprite-interactive',
            text: '<b>Obtén tu código de revendedor. </b> Asegúrate de que tus clientes compren con tu código revendedor.'
        },
        {
            icon: 'icon-sprite-percent',
            text: '<b>Gana $30.000</b> por cada máquina que vendas. '
        },
        {
            icon: 'icon-sprite-dollar',
            text: '<b>Recibe tus ganancias</b> al cierre de cada mes.'
        }
    ];

    constructor(
        // @Inject(PLATFORM_ID) private platformId: any,
        private _el: ElementRef
    ) {
        super(_el);
    }

    // public goToUrl(url: string, event: Event): void {
    //     if (isPlatformBrowser(this.platformId)) {
    //         window.open(url, '_blank');
    //         traceButton2(event, 'typeform');
    //     }
    // }

    public navigateToSection(className: string): void {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 500);
    }
}
