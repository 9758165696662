import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ICardPlan {
    name: string;
    commission?: string;
    detail: { supvalue?: string; subtext?:string, value?: string; price?: number; link: string; };
    button: { text: string; route?: string };
    list?: Array<any>;
}

@Component({
    selector: 'app-card-plan',
    templateUrl: './card-plan.component.html',
    styleUrls: ['./card-plan.component.scss']
})
export class CardPlanComponent {
    @Input() public data: ICardPlan;
    @Input() public selected: boolean;
    @Output() public selection: EventEmitter<any> = new EventEmitter<any>();

}
