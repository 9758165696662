import { DATA_PRICE_ASSOCIATION } from "../shared/section-price/data-price";

const formatterPrice = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
});

export interface Tariff {
    title: string;
    options: Array<TariffOption>;
}

export interface TariffOption {
    title: string;
    tooltip?: { title: string; text: string; size?: number };
    cards?: Array<Card>;
    columnCards?: Array<{ id: string; title: string; subtitle: string; isSelected: boolean; }>;
}

export interface Card {
    id: string;
    title: string;
    subtext?: string;
    suptext?: string;
    sup_value?: number;
    price?: number;
    percent?: string;
    sub_value?: string;
    value?: string | number;
    count?: number;
    isSelected: boolean;
    selectOptions?: Array<SelectOption>;
    isEdit?: boolean;
}

export interface SelectOption {
    title: string;
    price: number;
    sup_value?: number;
    productId: string;
    id?: number
}
export interface DataInstallment {
    title: string;
    body: Array<DataInstallmentOption>;
    footer: {
        caption: string;
        total: number;
        value?: string | number;
        label?: string;
        options?: Array<string | number>;
        info?: string;
    };
}

export interface DataInstallmentOption {
    caption: string;
    label: string;
    value: string | number;
    options: Array<string | number>;
}

export interface IDeviceFeature {
    name: string;
    device: string;
    options: Array<{ icon: string; text: string }>;
    features: Array<{ icon: string; text: string; tooltip?: string; tooltipHelperClass?: string }>;
    delivery: { fireText: string, fireTextRegion?: string, showFire: boolean, textRM: string, textRegion: string };
}

export const dataType: {
    'mini': IDeviceFeature,
    'mini-s': IDeviceFeature,
    // 'pro': IDeviceFeature,
    'se': IDeviceFeature,
    'pro-semi-nueva': IDeviceFeature,
    'se-semi-nueva': IDeviceFeature,
    'mini-semi-nueva': IDeviceFeature,
    'mini-s-semi-nueva': IDeviceFeature,
} = {
    'mini': {
        name: 'Mini',
        device: 'preview-sunmi-mini',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card2',
            text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-file',
            text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
        }, {
            icon: 'icon-sprite-attach-money',
            text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: 'icon-sprite-sim-card',
            text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
            tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        }, {
            icon: 'icon-sprite-track',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award',
            text: 'Garantía de <b>2 años.</b>'
        }, {
            icon: 'icon-sprite-battery',
            text: 'Batería de<b> larga duración.</b>'
        }],
        delivery: {
            fireText: '1 a 6 días hábiles RM',
            fireTextRegion: "2 a 10 días hábiles regiones",
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    },
    'mini-semi-nueva': {
        name: 'Mini Semi Nueva',
        device: 'preview-sunmi-mini',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card2',
            text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-file',
            text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
        }, {
            icon: 'icon-sprite-attach-money',
            text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: 'icon-sprite-sim-card',
            text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
            tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        }, {
            icon: 'icon-sprite-track',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award',
            text: 'Garantía de <b>6 meses.</b>'
        }, {
            icon: 'icon-sprite-battery',
            text: 'Batería de<b> larga duración.</b>'
        }],
        delivery: {
            fireText: '25 días hábiles',
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    },
    'mini-s': {
        name: 'Mini S',
        device: 'preview-sunmi-mini-s',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card2',
            text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-file',
            text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
        }, {
            icon: 'icon-sprite-attach-money',
            text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: 'icon-sprite-sim-card',
            text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
            tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        }, {
            icon: 'icon-sprite-scan',
            text: '<b>Escáner profesional</b> integrado.'
        }, {
            icon: 'icon-sprite-track',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award',
            text: 'Garantía de <b>2 años.</b>'
        }, {
            icon: 'icon-sprite-battery',
            text: 'Batería de<b> larga duración.</b>'
        }],
        delivery: {
            fireText: '1 a 6 días hábiles RM',
            fireTextRegion: "2 a 10 días hábiles regiones",
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    },
    'mini-s-semi-nueva': {
        name: 'Mini S Semi Nueva',
        device: 'preview-sunmi-mini-s',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }],
        features: [{
            icon: 'icon-sprite-credit-card2',
            text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
        }, {
            icon: 'icon-sprite-file',
            text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
        }, {
            icon: 'icon-sprite-attach-money',
            text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
            //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
        }, {
            icon: 'icon-sprite-sim-card',
            text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
            tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
        }, {
            icon: 'icon-sprite-scan',
            text: '<b>Escáner profesional</b> integrado.'
        }, {
            icon: 'icon-sprite-track',
            text: '<b>Despacho Gratis</b> a todo Chile.'
        }, {
            icon: 'icon-sprite-award',
            text: 'Garantía de <b>6 meses.</b>'
        }, {
            icon: 'icon-sprite-battery',
            text: 'Batería de<b> larga duración.</b>'
        }],
        delivery: {
            fireText: '25 días hábiles',
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    },
    // 'pro': {
    //     name: 'Pro',
    //     device: 'terminal-demonstartion',
    //     options: [{
    //         icon: 'icon-sprite-calendar-today',
    //         text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
    //     }, {
    //         icon: 'icon-sprite-percent',
    //         text: 'Comisiones más</br> bajas garantizadas.'
    //     }, {
    //         icon: 'icon-sprite-pos',
    //         text: 'Punto de venta con</br> catálogo e inventario.'
    //     }, {
    //         icon: 'icon-sprite-doc',
    //         text: 'Emisión ilimitada de</br> boletas y facturas por</br> correo electrónico.'
    //     }],
    //     features: [
    //         {
    //             icon: 'icon-sprite-credit-card2',
    //             text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
    //         }, {
    //             icon: 'icon-sprite-file',
    //             text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
    //         }, {
    //             icon: 'icon-sprite-attach-money',
    //             text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
    //             tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
    //         }, {
    //             icon: 'icon-sprite-printer',
    //             text: '<b>Impresión física de comprobante</b> de<br/> alta velocidad.',
    //         }, {
    //             icon: 'icon-sprite-sim-card',
    //             text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
    //             tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
    //         }, {
    //             icon: 'icon-sprite-track',
    //             text: '<b>Despacho Gratis</b> a todo Chile.'
    //         }, {
    //             icon: 'icon-sprite-award',
    //             text: 'Garantía de <b>2 años.</b>'
    //         }, {
    //             icon: 'icon-sprite-rolls',
    //             text: '5 rollos de papel térmico <b>gratis.</b>'
    //         }, {
    //             icon: 'icon-sprite-battery',
    //             text: 'Batería de<b> larga duración.</b>'
    //         }, {
    //             icon: 'icon-sprite-camera',
    //             text: 'Cámara de <b>5 megapixeles</b>'
    //         }, {
    //             icon: 'icon-sprite-screen',
    //             text: '<b>Pantalla táctil</b> de 5.5”',
    //             tooltip: 'Pantalla Táctil 5.5" HD, Resolución: 1440x720px',
    //             tooltipHelperClass: 'w-196'
    //         }
    //     ],
    //     delivery: {
    //         fireText: '25 días hábiles',
    //         showFire: false,
    //         textRM: '1 a 4 días hábiles RM',
    //         textRegion: '2 a 8 días hábiles regiones'
    //     }
    // },
    'se': {
        name: 'SE',
        device: 'terminal-demonstartion-se',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc',
            text: 'Emisión ilimitada de</br> boletas y facturas por</br> correo electrónico.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card2',
                text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
            }, {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            }, {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: 'icon-sprite-printer',
                text: '<b>Impresión física de comprobante</b> de<br/> alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
                tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-track',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award',
                text: 'Garantía de <b>2 años.</b>'
            }, {
                icon: 'icon-sprite-rolls',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery',
                text: 'Batería de<b> larga duración.</b>'
            }, {
                icon: 'icon-sprite-camera',
                text: 'Cámara de <b>0,3 megapixeles</b>'
            }, {
                icon: 'icon-sprite-screen',
                text: '<b>Pantalla táctil</b> de 5”',
                tooltip: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                tooltipHelperClass: 'w-196'
            }
        ],
        delivery: {
            fireText: '1 a 6 días hábiles RM',
            fireTextRegion: "2 a 10 días hábiles regiones",
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    },
    'se-semi-nueva': {
        name: 'SE Semi Nueva',
        device: 'terminal-demonstartion-se',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc',
            text: 'Emisión ilimitada de</br> boletas y facturas por</br> correo electrónico.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card2',
                text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
            }, {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            }, {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: 'icon-sprite-printer',
                text: '<b>Impresión física de comprobante</b> de<br/> alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
                tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-track',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award',
                text: 'Garantía de <b>6 meses.</b>'
            }, {
                icon: 'icon-sprite-rolls',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery',
                text: 'Batería de<b> larga duración.</b>'
            }, {
                icon: 'icon-sprite-camera',
                text: 'Cámara de <b>0,3 megapixeles</b>'
            }, {
                icon: 'icon-sprite-screen',
                text: '<b>Pantalla táctil</b> de 5”',
                tooltip: 'Pantalla Táctil 5" FW+, Resolución: 960x480px',
                tooltipHelperClass: 'w-196'
            }
        ],
        delivery: {
            fireText: '25 días hábiles',
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    },
    'pro-semi-nueva': {
        name: 'Pro Semi Nueva',
        device: 'terminal-demonstartion',
        options: [{
            icon: 'icon-sprite-calendar-today',
            text: '100% de tus ventas</br> en solo 1 día hábil de </br> abono.'
        }, {
            icon: 'icon-sprite-percent',
            text: 'Comisiones más</br> bajas garantizadas.'
        }, {
            icon: 'icon-sprite-pos',
            text: 'Punto de venta con</br> catálogo e inventario.'
        }, {
            icon: 'icon-sprite-doc',
            text: 'Emisión ilimitada de</br> boletas y facturas por</br> correo electrónico.'
        }],
        features: [
            {
                icon: 'icon-sprite-credit-card2',
                text: '<b>Acepta tarjetas</b> con banda <br/>magnética, chip y sin contacto.'
            }, {
                icon: 'icon-sprite-file',
                text: '<b>Recibe pago con tarjeta</b> y emite<br/> voucher válido como boleta<br/> electrónica.'
            }, {
                icon: 'icon-sprite-attach-money',
                text: '<b>Recibe pago con efectivo</b> y emite<br/> boleta o factura electrónica.',
                //tooltip: 'La emisión de factura será desde tu espacio de trabajo online. Próximamente podrás hacerlo desde tu POS.'
            }, {
                icon: 'icon-sprite-printer',
                text: '<b>Impresión física de comprobante</b> de<br/> alta velocidad.',
            }, {
                icon: 'icon-sprite-sim-card',
                text: '<b>Chip de Internet gratis</b> con gigas<br/> libres y Conexión Wifi.',
                tooltip: 'EL tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.'
            }, {
                icon: 'icon-sprite-track',
                text: '<b>Despacho Gratis</b> a todo Chile.'
            }, {
                icon: 'icon-sprite-award',
                text: 'Garantía de <b>6 meses.</b>'
            }, {
                icon: 'icon-sprite-rolls',
                text: '5 rollos de papel térmico <b>gratis.</b>'
            }, {
                icon: 'icon-sprite-battery',
                text: 'Batería de<b> larga duración.</b>'
            }
        ],
        delivery: {
            fireText: '1 a 6 días hábiles RM',
            fireTextRegion: "2 a 10 días hábiles regiones",
            showFire: false,
            textRM: '1 a 3 días hábiles RM',
            textRegion: '2 a 7 días hábiles regiones'
        }
    }

}

export const templateOPtionsGeneral: Array<Tariff> = [{
    title: 'OPCIONES DE PAGO',
    options: [{
        title: 'Al contado',
        cards: [{
            id: 'Terminal,',
            title: 'Pago único',
            subtext: 'Hasta 12 cuotas sin interés con tarjetas de crédito.',
            price: 0,
            sub_value: '+ IVA',
            count: 1,
            isSelected: true,
        }]
    }, {
        title: 'En cuotas',
        tooltip: {
            title: 'Sujeto a evaluación financiera.',
            text: 'Tu compra será sujeta a <br> evaluación en un máximo de 2 <br> horas hábiles y serás notificado a <br> través correo electrónico o <br> whatsapp.'
        },
        cards: [{
            id: 'T1PRO3,',
            title: ``,
            subtext: 'Descontado automáticamente de los abonos.',
            price: 0,
            sub_value: '+ IVA mensuales',
            count: 1,
            isSelected: false,
        }]
    }],
    // }, {
    //     title: 'PLANES',
    //     options: [{
    //         title: 'Plan',
    //         cards: [{
    //             id: 'TUU',
    //             title: 'Gratis',
    //             isSelected: true,
    //         }]
    //     }]
}];

export const templateOPtionsSemi: Array<Tariff> = [{
    title: 'OPCIONES DE PAGO',
    options: [{
        title: 'Al contado',
        cards: [{
            id: 'P2SEMI',
            title: 'Pago único',
            subtext: 'Hasta 12 cuotas sin interés con tarjetas de crédito.',
            price: 0,
            sub_value: '+ IVA',
            count: 1,
            isSelected: true,
        }]
    }],
},
    // {
    //     title: 'PLANES',
    //     options: [{
    //         title: 'Plan',
    //         cards: [{
    //             id: 'TUU',
    //             title: 'Gratis',
    //             isSelected: true,
    //         }]
    //     }]
    // }
]



export const dataOptions = {
    'mini': {
        id: 'T1MINI,',
        price: DATA_PRICE_ASSOCIATION['mini'].price,
        installmentId: 'T1MINI3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['mini'].installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['mini'].installmentPrice) + ` + 2 Cuotas de`,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }],
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ],
        commission: {
            id: 'T1MINICOM,',
            price: 39900,
            installmentId: 'T1MINICOM3,',
            installmentPrice: 13300,
            installmentText: `Pie de $13.300 + 2 Cuotas de`,
        }
    },
    'mini-semi-nueva': {
        id: 'MINISEMI,',
        price: DATA_PRICE_ASSOCIATION['mini-semi-nueva'].price,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }],
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ]
    },
    'mini-s': {
        id: 'T1MINIS,',
        price: DATA_PRICE_ASSOCIATION['mini-s'].price,
        installmentId: 'T1MINIS3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['mini-s'].installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION["mini-s"].installmentPrice) + ` + 2 Cuotas de`,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }]
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ]
    },
    'mini-s-semi-nueva': {
        id: 'MINISSEMI,',
        price: DATA_PRICE_ASSOCIATION['mini-s-semi-nueva'].price,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }]
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        }
        ]
    },
    // 'pro': {
    //     id: 'Terminal,',
    //     price: DATA_PRICE_ASSOCIATION['pro'].price,
    //     installmentId: 'T1PRO3,',
    //     installmentPrice: DATA_PRICE_ASSOCIATION['pro'].installmentPrice,
    //     installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['pro'].installmentPrice) + ` + 2 Cuotas de`,
    //     options: [
    //         //     {
    //         //     title: 'ACCESORIOS',
    //         //     options: [{
    //         //         title: 'Funda de silicona',
    //         //         cards: [{
    //         //             title: 'No gracias',
    //         //             isSelected: true
    //         //         }, {
    //         //             id: 'FUNDA,',
    //         //             title: 'Funda de silicona',
    //         //             price: 8000,
    //         //             sub_value: '+IVA',
    //         //             count: 1,
    //         //             isSelected: false,
    //         //         }]
    //         //     }]
    //         // },
    //         {
    //             title: 'COMPLEMENTOS',
    //             options: [{
    //                 title: 'Firma electrónica',
    //                 cards: [{
    //                     title: 'No gracias',
    //                     isSelected: true
    //                 }, {
    //                     productId: 'F1,',
    //                     title: 'Firma electrónica simple por 1 año.',
    //                     price: 11790,
    //                     sub_value: '+ IVA',
    //                     isSelected: false,
    //                     selectOptions: [{
    //                         productId: 'F1,',
    //                         title: 'Firma electrónica simple por 1 año',
    //                         price: 11790,
    //                     }, {
    //                         productId: 'F2,',
    //                         title: 'Firma electrónica simple por 2 años',
    //                         price: 16790,
    //                     }, {
    //                         productId: 'F3,',
    //                         title: 'Firma electrónica simple por 3 años',
    //                         price: 19790,
    //                     }]
    //                 }]
    //             }]
    //         }, {
    //  options: [{
    //  title: 'Chip de internet',
    //  tooltip: {
    //  text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
    //  size: 'lg'
    //  },
    //  columnCards: [{
    //  id: 'CGE,',
    //  title: 'Entel',
    //  subtitle: 'Gratis',
    //  isSelected: true
    //  }, {
    //  id: 'CGM,',
    //  title: 'Movistar',
    //  subtitle: 'Gratis',
    //  isSelected: false
    //  }]
    //  }]},
    //         {
    //             title: 'INSUMOS',
    //             options: [{
    //                 title: 'Rollos de papel térmico',
    //                 cards: [{
    //                     title: 'No gracias',
    //                     isSelected: true,
    //                 }, {
    //                     productId: '20RO,',
    //                     title: '+ 20 Rollos de papel térmico',
    //                     price: 8500,
    //                     sub_value: '+ IVA',
    //                     count: 1,
    //                     isSelected: false,
    //                     selectOptions: [{
    //                         productId: '20RO,',
    //                         title: '+ 20 Rollos de papel térmico',
    //                         price: 8500,
    //                     }, {
    //                         productId: '50RO,',
    //                         title: '+ 50 Rollos de papel térmico',
    //                         price: 19900,
    //                     }, {
    //                         productId: '100RO,',
    //                         title: '+ 100 Rollos de papel térmico',
    //                         price: 37900,
    //                     },
    //                         //  {
    //                         //     productId: 'RO,',
    //                         //     title: '+ 200 Rollos de papel térmico',
    //                         //     price: 66000,
    //                         // }
    //                     ]
    //                 }]
    //             }]
    //         }]
    // },
    'se': {
        id: 'P2SE,',
        price: DATA_PRICE_ASSOCIATION['se'].price,
        installmentId: 'P2SE3,',
        installmentPrice: DATA_PRICE_ASSOCIATION['se'].installmentPrice,
        installmentText: `Pie de ` + formatterPrice.format(DATA_PRICE_ASSOCIATION['se'].installmentPrice) + ` + 2 Cuotas de`,
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                }]
            }, {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        },
                            //  {
                            //     productId: 'RO,',
                            //     title: '+ 200 Rollos de papel térmico',
                            //     price: 66000,
                            // }
                        ]
                    }]
                }]
            },
            {
                title: 'ACCESORIOS',
                options: [{
                    title: 'Funda de silicona',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true
                    }, {
                        id: 'FUNDAP2SE,',
                        title: 'Funda de silicona',
                        price: 8000,
                        sub_value: '+IVA',
                        count: 1,
                        isSelected: false,
                        counter:true
                    }]
                }]
            },
        ]
    },
    'se-semi-nueva': {
        id: 'SESEMI,',
        price: DATA_PRICE_ASSOCIATION['se-semi-nueva'].price,
        options: [
            {
                title: 'COMPLEMENTOS',
                options: [{
                    title: 'Firma electrónica',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true
                    }, {
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año.',
                        price: 11790,
                        sub_value: '+ IVA',
                        isSelected: false,
                        selectOptions: [{
                            productId: 'F1,',
                            title: 'Firma electrónica simple por 1 año',
                            price: 11790,
                        }, {
                            productId: 'F2,',
                            title: 'Firma electrónica simple por 2 años',
                            price: 16790,
                        }, {
                            productId: 'F3,',
                            title: 'Firma electrónica simple por 3 años',
                            price: 19790,
                        }]
                    }]
                }]
            }, {
                options: [{
                    title: 'Chip de internet',
                    tooltip: {
                        text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                        size: 'lg'
                    },
                    columnCards: [{
                        id: 'CGE,',
                        title: 'Entel',
                        subtitle: 'Gratis',
                        isSelected: true
                    }, {
                        id: 'CGM,',
                        title: 'Movistar',
                        subtitle: 'Gratis',
                        isSelected: false
                    }]
                }]
            },
            {
                title: 'INSUMOS',
                options: [{
                    title: 'Rollos de papel térmico',
                    cards: [{
                        title: 'No gracias',
                        isSelected: true,
                    }, {
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                        sub_value: '+ IVA',
                        count: 1,
                        isSelected: false,
                        selectOptions: [{
                            productId: '20RO,',
                            title: '+ 20 Rollos de papel térmico',
                            price: 8500,
                        }, {
                            productId: '50RO,',
                            title: '+ 50 Rollos de papel térmico',
                            price: 19900,
                        }, {
                            productId: '100RO,',
                            title: '+ 100 Rollos de papel térmico',
                            price: 37900,
                        },
                            //  {
                            //     productId: 'RO,',
                            //     title: '+ 200 Rollos de papel térmico',
                            //     price: 66000,
                            // }
                        ]
                    }]
                }]
            }]
    },
    'pro-semi-nueva': {
        id: 'P2SEMI,',
        price: DATA_PRICE_ASSOCIATION['pro-semi-nueva'].price,
        options: [{
            title: 'COMPLEMENTOS',
            options: [{
                title: 'Firma electrónica',
                cards: [{
                    title: 'No gracias',
                    isSelected: true
                }, {
                    productId: 'F1,',
                    title: 'Firma electrónica simple por 1 año.',
                    price: 11790,
                    sub_value: '+ IVA',
                    isSelected: false,
                    selectOptions: [{
                        productId: 'F1,',
                        title: 'Firma electrónica simple por 1 año',
                        price: 11790,
                    }, {
                        productId: 'F2,',
                        title: 'Firma electrónica simple por 2 años',
                        price: 16790,
                    }, {
                        productId: 'F3,',
                        title: 'Firma electrónica simple por 3 años',
                        price: 19790,
                    }]
                }]
            }]
        }, {
            options: [{
                title: 'Chip de internet',
                tooltip: {
                    text: 'El tráfico es de uso exclusivo del terminal para su óptimo funcionamiento y no debe ser utilizado con otra finalidad. Aquellos chips que no tengan tráfico por más de 3 meses serán desactivados.',
                    size: 'lg'
                },
                columnCards: [{
                    id: 'CGE,',
                    title: 'Entel',
                    subtitle: 'Gratis',
                    isSelected: true
                }, {
                    id: 'CGM,',
                    title: 'Movistar',
                    subtitle: 'Gratis',
                    isSelected: false
                }]
            }]
        },
        {
            title: 'INSUMOS',
            options: [{
                title: 'Rollos de papel térmico',
                cards: [{
                    title: 'No gracias',
                    isSelected: true,
                }, {
                    productId: '20RO,',
                    title: '+ 20 Rollos de papel térmico',
                    price: 8500,
                    sub_value: '+ IVA',
                    count: 1,
                    isSelected: false,
                    selectOptions: [{
                        productId: '20RO,',
                        title: '+ 20 Rollos de papel térmico',
                        price: 8500,
                    }, {
                        productId: '50RO,',
                        title: '+ 50 Rollos de papel térmico',
                        price: 19900,
                    }, {
                        productId: '100RO,',
                        title: '+ 100 Rollos de papel térmico',
                        price: 37900,
                    },
                        //  {
                        //     productId: 'RO,',
                        //     title: '+ 200 Rollos de papel térmico',
                        //     price: 66000,
                        // }
                    ]
                }]
            }]
        }]
    }
};
