import { Component, ElementRef, Input } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { ScrollToClass } from '../scrollTo.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-all-in-one',
    templateUrl: './all-in-one.component.html',
    styleUrls: ['./all-in-one.component.scss']
})
export class AllInOneComponent extends ScrollToClass {
    @Input() public loadingImages: boolean;
    @Input() public source: string;

    constructor(
        private _el: ElementRef,
        private router: Router
    ) {
        super(_el);
    }

    track(event: Event) {
        traceButton2(event, 'navigate');
    }

    public showPosHome(): void {
        this.router.navigate(['/']).then(() => {
            window.scroll(0, 0);
            setTimeout(() => {
                this.scrollToSection("sectionPrice");
            }, 500);
        });
        this.scrollToSection("sectionPrice");
    }
}
