// import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Input,
    // Inject,
    //PLATFORM_ID
} from '@angular/core';
// import { traceButton2 } from '../../../../../common/services/button-trace';
declare var hbspt: any;
@Component({
    selector: 'app-software-integration',
    templateUrl: './software-integration.component.html',
    styleUrls: ['./software-integration.component.scss']
})
export class SoftwareIntegrationComponent implements AfterViewInit {
    @Input() public loadingImages: boolean;

    constructor(
        // @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngAfterViewInit(): void {
        if (hbspt !== undefined){
            hbspt.forms.create({
                portalId: "47115837",
                formId: "ce487bd1-1dfb-4907-8c14-2b5895309762",
                target: '#hubspotForm'
            });
        }
    }
    // public goToUrl(url: string): void {
    //     if (isPlatformBrowser(this.platformId)) {
    //         window.open(url, '_blank');
    //     }
    // }

    // public track(event: Event) {
    //     traceButton2(event, 'typeform');
    // }

}
