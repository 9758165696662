export const DATA_FOOTER = {
    dataFooterTuu: [
        {
            id1: '',
            id2: 'footer-productos-pago',
            link: '/pago',
            text: 'TUU Pago'
        },
        {
            id1: '',
            id2: 'footer-productos-reserva',
            link: '/tuu-reserva',
            text: 'TUU Reserva'
        },
        {
            id1: '',
            id2: 'footer-productos-boleta',
            link: '/boleta-electronica',
            text: 'TUU Boleta'
        },
        {
            id1: '',
            id2: 'footer-productos-inventario',
            link: '/inventario',
            text: 'TUU Inventario'
        },
        {
            id1: '',
            id2: 'footer-productos-pdv',
            link: '/punto-de-venta',
            text: 'TUU Punto de venta'
        },
        {
            id1: '',
            id2: 'footer-productos-catalogo',
            link: '/catalogo',
            text: 'TUU Catálogo'
        }
    ],
    // dataFooterService : [
    //     {
    //         link: '',
    //         text: 'TUU Emprende (PRONTO)',
    //         disable: 'disable'
    //     },
    //     {
    //         link: '',
    //         text: 'TUU Contador (PRONTO)',
    //         disable: 'disable'
    //     }
    // ],
    dataFooterProgram: [
        {
            id1: '',
            id2: 'footer-productos-revendedores',
            link: '/revendedores',
            text: 'TUU Revendedores'
        },
        {
            id1: '',
            id2: 'footer-productos-partners',
            link: '/partner',
            text: 'TUU Partner'
        }
    ],
    dataFooterSupport: [
        {
            id1: '',
            id2: 'footer-at-cliente-documentacion',
            link: 'https://help.tuu.cl/',
            text: 'Documentación'
        },
        {
            id1: '',
            id2: 'footer-at-cliente-videotutoriales',
            link: 'https://www.youtube.com/playlist?list=PLJm1NCE-HYjuBuzi-mmmbHLXJrIycw2Gx',
            text: 'Videos Tutoriales'
        },
        {
            id1: '',
            id2: 'footer-at-cliente-contact',
            link: '/contactar-atencion-cliente',
            text: 'Obtener Ayuda'
        },
        {
            id1: '',
            id2: 'footer-at-cliente-ventas',
            link: '/contactar-con-ventas',
            text: 'Contactar con ventas'
        }
    ]
};

export const NEW_DATA_FOOTER = ({ pagePrefix, env }): any => {
    return {
        menuProducts: [
            {
                id: `${pagePrefix}footer-productos-pago`,
                route: '/pago',
                text: 'Pagos presencial'
            },
            {
                id: `${pagePrefix}footer-productos-boleta`,
                route: '/boleta-electronica',
                text: 'Boleta electrónica'
            },
            {
                id: `${pagePrefix}footer-productos-inventario`,
                route: '/inventario',
                text: 'Inventario'
            },
            {
                id: `${pagePrefix}footer-productos-pdv`,
                route: '/punto-de-venta',
                text: 'Punto de venta'
            },
            {
                id: `${pagePrefix}footer-productos-catalogo`,
                route: '/catalogo',
                text: 'Catálogo'
            },
            {
                id: `${pagePrefix}footer-productos-reserva`,
                route: '/tuu-reserva',
                text: 'Reserva'
            },
            {
                id: `${pagePrefix}`,
                text: 'Link de pago'
            },
            {
                id: `${pagePrefix}`,
                text: 'Checkout eCommerce'
            },
            {
                id: `${pagePrefix}`,
                text: 'Evento'
            },
        ],
        menuPrice: [
            {
                id: `${pagePrefix}footer-precios-planes`,
                route: '/precios',
                text: 'Planes'
            },
            {
                id: `${pagePrefix}footer-precios-insumos`,
                route: '/rollos-e-insumos',
                text: 'Rollos e Insumos'
            }
        ],
        menuProgram: [
            {
                id: `${pagePrefix}footer-programa-revendedor`,
                route: '/revendedores',
                text: 'Revendedores'
            },
            {
                id: `${pagePrefix}footer-programa-partners`,
                route: '/partner',
                text: 'Partner'
            }
        ],
        menuOther: [
            {
                id: `${pagePrefix}footer-nosotros-blog`,
                href: `${env.TUU_ARTICULOS}`,
                text: 'Blog'
            },
            {
                id: `${pagePrefix}footer-nosotros-testimonios`,
                text: 'Testimonios'
            },
            {
                id: `${pagePrefix}footer-nosotros-recursos`,
                text: 'Recursos Gratuitos'
            }
        ],
        menuSupport: [
            {
                id: `${pagePrefix}footer-soporte-docu`,
                href: 'https://help.tuu.cl/',
                text: 'Documentación'
            },
            {
                id: `${pagePrefix}footer-soporte-tuto`,
                href: 'https://www.youtube.com/playlist?list=PLJm1NCE-HYjuBuzi-mmmbHLXJrIycw2Gx',
                text: 'Videos Tutoriales'
            },
            {
                id: `${pagePrefix}footer-soporte-ventas`,
                route: '/contactar-con-ventas',
                text: 'Contactar con ventas'
            },
            {
                id: `${pagePrefix}footer-soporte-capacitacion`,
                href: 'https://calendly.com/agenda-haulmer/',
                text: 'Agendar Capacitación'
            },
            {
                id: `${pagePrefix}footer-soporte-ayuda`,
                route: '/contactar-atencion-cliente',
                text: 'Obtener Ayuda'
            },
            {
                id: `${pagePrefix}footer-soporte-reclamos`,
                href: 'https://survey.typeform.com/to/zQ7I32J9#empresa=TUU&issuetype=11071',
                text: 'Sugerencias o Reclamos'
            },
            {
                id: `${pagePrefix}footer-soporte-seguimiento`,
                route: '/seguimiento',
                text: 'Seguimiento de Solicitud'
            }
        ]
    }
};
