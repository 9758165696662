import { Component, EventEmitter, Output, Input } from '@angular/core';
import { traceButton2 } from '../../../../../../../../common/services/button-trace';

@Component({
    selector: 'app-dropdown-menu-programs',
    templateUrl: './dropdown-menu-programs.component.html',
    styleUrls: ['./dropdown-menu-programs.component.scss']
})
export class DropdownMenuProgramsComponent {
    @Output() public hideMenu = new EventEmitter<boolean>();
    @Input() public url;
    

    closeMenu(): void {
        this.hideMenu.emit(false);
    }

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }

}