import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-how-to-apply-mobile',
    templateUrl: './how-to-apply-mobile.component.html',
    styleUrls: ['./how-to-apply-mobile.component.scss']
})
export class HowToApplyMobileComponent {
    public currentTab = 0;
    public names = ['initial', 'your-offer', 'ready'];
    public imgName = this.names[0];


    constructor(
    ) { }

    public selectedTab(index){
        this.currentTab = index;
        this.imgName = this.names[index];
    }
}
