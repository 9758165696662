import { Component } from '@angular/core';
import { traceButton2 } from '../../../../../../common/services/button-trace';

@Component({
    selector: 'app-plan-cards',
    templateUrl: './plan-cards.component.html',
    styleUrls: ['./plan-cards.component.scss']
})
export class PlanCardsComponent {

    public listFirstCard = [
        {
            title: 'Pago',
            text: 'Acepta pagos con tarjetas'
        }, {
            title: 'Boleta',
            text: 'Emite sin límites'
        }, {
            title: 'Reserva',
            text: 'Agenda horas de clientes'
        }, {
            title: 'Punto de venta',
            text: 'Vende con rapidez'
        }, {
            title: 'Catálogo',
            text: 'Organiza todos tus productos'
        }, {
            title: 'Inventario',
            text: 'Controla el stock de tus productos'
        }
    ];

    public listSecondCard = [
        //'<b>Comisión más baja: 1,79%</b> con abono en 1 día hábil.',
        '<b>Funciones avanzadas para Reserva</b> con agendas y sucursales ilimitadas.',
        '<b>API para integrar la emisión de documentos</b> electrónicos.',
        '<b>Integración con los principales ecommerce</b> para la emisión de documentos electrónicos.',
        '<b>Emisión masiva de documentos</b> con Complemento Google Sheet.'
    ];

    public track(event:Event){
        traceButton2(event, 'navigate');
    }
}
