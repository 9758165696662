export const urlMapping = {
    'tuu-reserva': 'reserva',
    'punto-de-venta': 'pdv',
    'contactar-con-ventas': 'ventas',
    'revendedores': 'revendedor',
    'boleta-electronica': 'boleta',
    'rollos-e-insumos': 'insumos',
    'contactar-atencion-cliente': 'at-cliente',
    'cuotas-tuu': 'cuotas',
    'abono-inmediato': 'abono'
}