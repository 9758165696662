import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalMenuService } from '../../../services/modal-menu.service';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../../../common/services/button-trace';
import { urlMapping } from '../../../route-ids';


@Component({
    selector: 'app-mobile-menu-functions',
    templateUrl: './mobile-menu-functions.component.html',
    styleUrls: ['./mobile-menu-functions.component.scss']
})
export class MobileMenuFunctionsComponent implements OnInit {
    @Output() closeEventChild = new EventEmitter();

    isOpen: boolean;
    public url:string;

    constructor(
        private modalMenuService: ModalMenuService,
        private _router: Router,
    ) { }

    ngOnInit(): void {
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
        const queryParams = urlTree.queryParams;
        this.url = (queryParams?.['purchaseOrigin'] === 'reserva')
            ? (queryParams['subproduct'] === 'TUU' ? 'reserva' : 'reserva-plus')
            : this.url;
        setTimeout(() => {
            this.isOpen = true;
        }, 0);
    }

    back(): void {
        this.isOpen = false;

        setTimeout(() => {
            this.modalMenuService.closeModal();
        }, 400);
    }

    close(): void {
        this.isOpen = false;
        this.closeEventChild.emit();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public track(event: Event): void {
        traceButton2(event, 'navigate');
    }

}
