import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-inmediate-payment-page',
    templateUrl: './inmediate-payment-page.component.html'
})
export class InmediatePaymentPageComponent implements OnInit{
    public urlEnv = urlEnv;
    public showImages1 = false;
    public showImages2 = false;
    public showImages3 = false;
    isSmallScreen: boolean = false;
    private resizeTimeout;


    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.isSmallScreen = window.innerWidth < 994;
    }

    @HostListener('window:resize')
    onResize(): void {
        clearTimeout(this.resizeTimeout); // Limpiar el timeout anterior
        this.resizeTimeout = setTimeout(() => {
        this.isSmallScreen = window.innerWidth < 994;
        }, 200); // Debounce de 200ms
    }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta(): void {
        this._meta.setTitle('Recibe el abono inmediato de tus ventas con máquina POS TUU');
        this._meta.setTag('description', 'Recibe el abono inmediato de tus pagos con tarjetas en la máquina de pago TUU, recibe el pago de tus ventas en 15 minutos.');
        this._meta.setTag('keywords', 'TUU, abonos, abono inmediato, recibir pagos, pagos con tarjeta, punto de venta, máquina POS, máquina para pagar con tarjeta, POS');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-credit.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Recibe el abono inmediato de tus ventas con máquina POS TUU');
    }
}
