import { Component } from '@angular/core';

@Component({
    selector: 'app-section-inmdiate-payment-faq',
    templateUrl: './section-inmediate-payment-faq.component.html',
    styleUrls: ['./section-inmediate-payment-faq.component.scss']
})
export class SectionInmediatePaymentFaqComponent {
    public activePanel = -1;

    public itemsFAQ = [
        {
            title: '¿Cuánto es el monto máximo que puedo solicitar?',
            text: ['El monto máximo que tendrás disponible cada día será equivalente al 50% de las ventas diarias que hayas recibido con tarjeta de débito, con un tope de $500.000 diarios.']
        }, {
            title: '¿Cómo se cobrará el abono solicitado?',
            text: [
                'Si normalmente recibes tus abonos con la comisión de 1 día hábil, cuando solicites el Abono Inmediato, se te descontarán al día siguiente los costos asociados al Abono Inmediato.',
                'Si tienes comisión con 2, 5, 10, 15 o 25 días hábiles, los costos asociados a Abono Inmediato se te descontarán de tus abonos transcurrida esa cantidad de días desde el día en que hiciste la solicitud. Ejemplo: si tienes comisión 5 días hábiles, y solicitas Abono Inmediato un día Lunes, el cobro se te hará el día Lunes de la semana siguiente.'
            ]
        }, {
            title: '¿Cualquier comercio de TUU puede tener Abono Inmediato?',
            text: [
                'Abono Inmediato no está habilitado para todos los negocios, se deben cumplir ciertos criterios para tener disponible esta funcionalidad. Dentro de los criterios que deben cumplirse están: haber recibido pagos activamente durante los últimos 3 meses, ser cliente TUU con más de 6 meses de antigüedad, haber procesado más de 20 millones en transacciones, entre otros.',
                'La activación de Abono Inmediato es automática al cumplir los criterios, por lo que no se puede solicitar la activación manual.'
            ]
        }
    ];

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }

}
