import { Component, Inject, OnInit, PLATFORM_ID, HostListener } from '@angular/core';
import { MetaService } from '@ngx-meta/core';

import { urlEnv } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-credit-page',
    templateUrl: './credit-page.component.html'
})
export class CreditPageComponent implements OnInit {

    public urlEnv = urlEnv;

    public showImages1 = false;
    public showImages2 = false;
    public showImages3 = false;
    isSmallScreen: boolean = false;
    private resizeTimeout;

    constructor(
        private readonly _meta: MetaService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.isSmallScreen = window.innerWidth < 460;
    }

    @HostListener('window:resize')
    onResize(): void {
        clearTimeout(this.resizeTimeout); // Limpiar el timeout anterior
        this.resizeTimeout = setTimeout(() => {
        this.isSmallScreen = window.innerWidth < 460;
        }, 200); // Debounce de 200ms
    }

    ngOnInit(): void {
        this._setMeta();

        setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        }, 500);
    }

    private _setMeta(): void {
        this._meta.setTitle('Obtén un crédito de hasta $15 millones fácil y rápidoe');
        this._meta.setTag('description', 'Accede a TUU Crédito y obtén financiamiento para tu negocio directamente desde tu Espacio de Trabajo');
        this._meta.setTag('keywords', 'TUU, TUU Crédito, préstamo, crédito, financiamiento, pre-aprobado, Espacio de Trabajo, financiamiento continuo, depósito, simulador de crédito');
        this._meta.setTag('author', 'TUU');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.urlEnv.TUU_WEBSITE + 'assets/images/og/og-credit.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Obtén un crédito de hasta $15 millones fácil y rápidoe');
    }

}
