import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

interface SliderOptions {
    container: any;
    items: any;
    itemWidth: any;
    startPos: number;
    btnPrev?: any;
    btnNext?: any;
}

@Component({
    selector: 'app-section-how-it-works',
    templateUrl: './section-how-it-works.component.html',
    styleUrls: ['./section-how-it-works.component.scss']
})
export class SectionHowItWorksComponent implements OnInit, AfterViewInit {
    @Input() public loadingImages: boolean;
    @ViewChild('animationBlock') public animationBlock: ElementRef;

    public items = [
        {
            icon: 'icon-sprite-settings',
            suptitle: 'funcionamiento',
            title: '<b><i>Nada es más <br> simple</i></b> que TUU',
            text: 'Vender y gestionar tu negocio<br> <b>nunca fue tan sencillo.</b>'
        }, {
            icon: 'icon-sprite-catalogue',
            suptitle: null,
            title: '<b><i>Vende con catálogo</i></b> o manualmente',
            text: 'Escanea tus productos desde el catálogo o escribe los precios manualmente, <b>tan simple como una calculadora.</b>'
        }, {
            icon: 'icon-sprite-credit-card',
            suptitle: null,
            title: 'Recibe <b><i>TUU pago</i></b>',
            text: 'Selecciona el método de pago<br> con tarjeta y recíbelo con <b>todo<br> tipo de tarjetas y en cuotas.</br>'
        }, {
            icon: 'icon-sprite-file',
            suptitle: null,
            title: 'Elige el <b><i>tipo de documento</i></b>',
            text: 'Podrás elegir entre <b>boleta o factura electrónica,</b> exenta o afecta.'
        }, {
            icon: 'icon-sprite-check-circle',
            suptitle: null,
            title: 'Listo, <b><i>TUU venta realizada</i></b>',
            text: 'Con TUU inventario organizas y agilizas todos los procesos de tu negocio, <b>¡que no falte nada!</b>'
        }
    ];

    public startAnimation = false;
    public isMobile = false;
    public currentItem = 0;

    public sliderProps: SliderOptions;


    @HostListener('window:scroll')
    onScroll(): void {
        if (isPlatformBrowser(this.platformId) && !this.isMobile) {
            this.playAnimation();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < 671;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    public ngOnInit(): void {
        this.isMobile = window.innerWidth < 671;
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId) && this.isMobile) {
            setTimeout(() => {
                this.initSlider();
            }, 100);
        }
    }

    public playAnimation(): void {
        const section = this.animationBlock.nativeElement;

        if (section.getBoundingClientRect().top <= 56) {
            this.startAnimation = true;
        } else {
            this.startAnimation = false;
        }

        if (this.startAnimation) {
            const items = section.getElementsByClassName('sectionHowItWorks__text') as HTMLCollectionOf<HTMLElement>;

            for (let i = 1; i <= items.length - 1; i++) {
                if (items[i].getBoundingClientRect().top <= 56) {
                    section.classList.add('animate-' + i);
                    section.classList.remove('animate-' + (i - 1));

                    if (items[items.length - 1].getBoundingClientRect().bottom <= window.innerHeight) {
                        section.classList.add('stop-animation');
                    } else {
                        if (section.classList.contains('stop-animation')) {
                            section.classList.remove('stop-animation');
                        }
                    }
                } else {
                    if (section.classList.contains('animate-' + i)) {
                        section.classList.remove('animate-' + i);
                    }
                }
            }
        }
    }

    public moveSlider(btn): void {
        this.animateSlider(btn);
        this.updateButtonsState();
    }

    public initSlider(): void {
        const parrent = this.animationBlock.nativeElement;
        this.sliderProps = {
            container: <HTMLElement>parrent.getElementsByClassName('slider-ts')[0],
            items: <HTMLCollection>parrent.getElementsByClassName('item-ts'),
            itemWidth: <HTMLCollection>parrent.getElementsByClassName('item-ts')[0].getBoundingClientRect().width,
            startPos: 0,
            btnPrev: <HTMLElement>parrent.getElementsByClassName('prev')[0],
            btnNext: <HTMLElement>parrent.getElementsByClassName('next')[0],
        };

        for (let i = 0; i < this.sliderProps.items.length; i++) {
            this.sliderProps.items[this.sliderProps.startPos].classList.add('active');
            this.updateButtonsState();
        }
    }

    public updateButtonsState(): void {
        if (this.sliderProps.startPos === this.sliderProps.items.length - 1) {
            this.sliderProps.btnNext.disabled = true;
            this.sliderProps.btnPrev.disabled = false;
        } else if (this.sliderProps.startPos === 0) {
            this.sliderProps.btnNext.disabled = false;
            this.sliderProps.btnPrev.disabled = true;
        } else {
            this.sliderProps.btnNext.disabled = false;
            this.sliderProps.btnPrev.disabled = false;
        }
    }

    public animateSlider(button: string): void {
        if (button === 'next') {
            if (this.sliderProps.startPos < this.sliderProps.items.length - 1) {
                this.sliderProps.startPos++;
                this.sliderProps.container.style =
                    'transform: translateX(-' + (this.sliderProps.itemWidth * this.sliderProps.startPos) + 'px)';
            }
        } else if (button === 'prev') {
            if (this.sliderProps.startPos > 0) {
                this.sliderProps.startPos--;
                if (this.sliderProps.startPos > 0) {
                    this.sliderProps.container.style =
                        'transform: translateX(-' + (this.sliderProps.itemWidth * this.sliderProps.startPos) + 'px)';
                } else {
                    this.sliderProps.container.style =
                        'transform: translateX(' + (this.sliderProps.itemWidth * this.sliderProps.startPos) + 'px)';
                }
            }
        }
    }

}
