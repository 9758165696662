import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { traceButton2 } from '../../../../../common/services/button-trace';
import { urlMapping } from '../../shared/route-ids';


@Component({
    selector: 'app-section-questionary-view',
    templateUrl: './section-questionary-view.component.html',
    styleUrls: ['./section-questionary-view.component.scss']
})
export class SectionQuestionaryViewComponent implements OnInit  {
  public resultadoFinal: {
    caract1: string,
    caract2: string,
    url: string } | null = null;
  public currentStep: number = 1;
  public options: string;
  public recomendacion: string;
  public url: string;
  public urlDirect: string = '';
  public origin: string = 'cuestionario';
  public popupVisible = false;

  public form: FormGroup = new FormGroup({
    paso1: new FormControl(undefined),
    paso2: new FormControl(undefined),
    paso3: new FormControl(undefined),
  });

  @Input() isDirect: boolean;

  constructor(private router: Router,) {}

  ngOnInit() {
    const urlTree = this.router.parseUrl(this.router.url);
    const primarySegment = urlTree.root.children['primary'];
    const urlSegment = primarySegment?.segments[0]?.path || 'home';;
    this.url = urlMapping[urlSegment] || urlSegment;
    if(this.isDirect){
      this.urlDirect = '/directa'
      this.origin = 'cuestionario-venta-directa'
    }
}

  public nextStep(step: number, value: boolean, event: Event): void {
    this.form.get(`paso${step}`).setValue(value);
    this.currentStep++;

    if (this.currentStep === 4) {
      this.setFinalResult();
    }

    traceButton2(event, 'answer');
  }

  public isStepVisible(step: number): boolean {
    return this.currentStep === step;
  }

  public setFinalResult(): void {
    const escan = this.form.get('paso2').value;
    const impre = this.form.get('paso3').value;

    //SE
    if (escan && impre) {
      this.options = 'escaner,impresora';
      this.recomendacion = 'SE'
      this.resultadoFinal = {
        caract1: 'Cámara integrada con la que puedes escanear tus productos.',
        caract2: 'Impresora térmica de alta velocidad para imprimir tus documentos electrónicos.',
        url:this.urlDirect+"/comprar-se"
      };
    }

    //SE
    if (!escan && impre) {
      this.options = 'impresora';
      this.recomendacion = 'SE'
      this.resultadoFinal = {
        caract1: 'Máquina sin escaner profesional.',
        caract2: 'Impresora térmica de alta velocidad para imprimir tus documentos electrónicos.',
        url:this.urlDirect+"/comprar-se"
      };
    }

    //Mini S
    if (escan && !impre) {
      this.options = 'escaner';
      this.recomendacion = 'Mini-S'
      this.resultadoFinal = {
        caract1: 'Máquina portátil con escáner profesional para escanear tus productos.',
        caract2: 'No incluye impresora pero puedes enviar tus documentos electrónicos por correo.',
        url:this.urlDirect+"/comprar-mini-s"
      };
    }

    //Mini
    if (!escan && !impre) {
      this.options = undefined;
      this.recomendacion = 'Mini'
      this.resultadoFinal = {
        caract1: 'Máquina portátil más económica sin escáner profesional.',
        caract2: 'No incluye impresora pero puedes enviar tus documentos electrónicos por correo.',
        url:this.urlDirect+"/comprar-mini"
      };
    }
  }

  public redireccionar(event: Event): void {
    let params = {
      opciones:this.options,
      maquina_r:this.recomendacion,
      purchaseOrigin:this.origin
    }
    this.router.navigate([this.resultadoFinal.url], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
    traceButton2(event, 'answer');
  }
  public openPopup() {
    this.popupVisible = true;
  }

  public closePopup() {
    this.popupVisible = false;
  }
}