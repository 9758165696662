import {
    AfterViewInit,
    Component,
    // HostListener,
    // Inject,
    // OnInit,
    // PLATFORM_ID,
    // ViewChild,
} from '@angular/core';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { Contact } from '../../Contact';
// import { CONTACTS } from '../../../tuu/src/app/contacts.constants';
// import { ApiService } from '../../../tuu/src/app/shared/services/api.service';
// import {
//     FormControl,
//     FormGroup,
//     FormGroupDirective,
//     Validators,
// } from '@angular/forms';
// import { ReCaptchaV3Service } from 'ngx-captcha';
// import { isPlatformBrowser } from '@angular/common';
// import { HttpClient } from '@angular/common/http';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { MAX_EMAIL_CHARACTERS, COUNTRY_REGION, customEmailValidator, getEmailErrorMessage, sanitizePhone, getPhonePlaceholder, getPhoneErrorMessage, divideNames, changeValidation, changeCountry, EMPTY_PHONE_VALUE, EMPTY_PHONE_PLACEHOLDER } from '../../../common/contact-shared/contact-shared.component';
// import { traceButton2 } from '../../services/button-trace';
// import { Router } from '@angular/router';
// import { urlMapping } from '../../../tuu/src/app/shared/route-ids';

declare var hbspt: any;
@Component({
    selector: 'app-pop-up',
    templateUrl: './leaving-popup.component.TUU.html',
    styleUrls: ['./leaving-popup.component.TUU.scss'],
})
export class PopUpComponent implements AfterViewInit/*OnInit*/ {
    ngAfterViewInit(): void {
        if (hbspt !== undefined){
            hbspt.forms.create({
                portalId: "47115837",
                formId: "df58cab6-2ec7-447d-aede-5838190e2929",
                target: "#leavingForm",
            });
        }
    }
}
// @ViewChild(FormGroupDirective, { static: true })
// formDirective: FormGroupDirective;
// private region: any;
// private comuna: any;
// private activeCaptcha = false;
// public disableButton = false;
// public isReadOnly = false;
// private isSendForm = false;
// private captcha: any;
// public contact: Contact;
// public hidden: boolean;
// public allContactList: any;
// public logoWidth = '45px';
// public emailErrorMessage: string ;
// public phoneErrorMessage: string ;
// public url: string;

// public phone: FormControl = new FormControl(EMPTY_PHONE_VALUE, [
//     Validators.required
// ]);
// public email: FormControl = new FormControl('', [
//     Validators.required,
//     customEmailValidator(),
//     Validators.maxLength(MAX_EMAIL_CHARACTERS),
// ]);
// public name: FormControl = new FormControl('', [
//     Validators.required,
//     Validators.minLength(3),
//     Validators.maxLength(100),
// ]);
// public schedule = new FormControl('', [
//     Validators.required
// ]);
// public form: FormGroup = new FormGroup({
//     schedule: this.schedule,
//     phone: this.phone,
//     email: this.email,
//     name: this.name,
// });

// constructor(
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     @Inject(PLATFORM_ID) private platformId: any,
//     private apiService: ApiService,
//     private _http: HttpClient,
//     private snackBar: MatSnackBar,
//     private dialog: MatDialogRef<PopUpComponent>,
//     private reCaptchaV3Service: ReCaptchaV3Service,
//     private _router: Router
// ) {
//     this.allContactList = CONTACTS;
//     const urlTree = this._router.parseUrl(this._router.url);
//     const primarySegment = urlTree.root.children['primary'];
//     const urlSegment = primarySegment?.segments[0]?.path || 'home';;
//     this.url = urlMapping[urlSegment] || urlSegment;
// }

// @HostListener('window:resize', ['$event'])
// onResize() { }

// ngOnInit(): void {
//     this.contact = this.getContact(this.data.TLD);
//     if (isPlatformBrowser(this.platformId)) {
//         this.StartCaptcha();
//     }
//     //Default Properties
//     this.region = COUNTRY_REGION;

//     this.changeValidation(this.contact)
// }

// private StartCaptcha(): void {
//     this.reCaptchaV3Service.execute(
//         this.data.siteKey,
//         'contactar_ventas',
//         (token) => {
//             this.activeCaptcha = true;
//             this.captcha = token;
//             let element =
//                 document.getElementsByClassName('grecaptcha-badge')[0];
//             element.setAttribute(
//                 'style',
//                 element.getAttribute('style') + 'visibility: hidden;'
//             );
//         }
//     );
// }

// changeValidation(contact: Contact): void {
//     this.form = changeValidation(this.form, contact.country_code);
//     this.hidden = changeCountry(contact.country_code);
// }

// public getContact(TLD: string): Contact {
//     const aux = TLD === 'mx' ? 'me' : TLD;
//     return this.data.contactList.filter((c) => c.country_code === aux)[0];
// }
// private showMessage(bodyText, btnText, duration, reload?): void {
//     const bar = this.snackBar.open(bodyText, btnText, {
//         duration: duration,
//         panelClass: 'snackBar-btn',
//     });

//     if (reload) {
//         bar.afterDismissed().subscribe(() => location.reload());
//     }
// }

// btnMsg(): string {
//     if (this.disableButton === false) {
//         return 'Enviar';
//     } else {
//         return 'Enviando Solicitud...';
//     }
// }

// public track(event:Event){
//     traceButton2(event,'form');
// }

// public sendForm(): void {
//     this.disableButton = true;
//     this.isReadOnly = true;
//     const values = this.form.value;

//     const fullName = divideNames(values.name);
//     const gSheetsBody = {
//         name: fullName[0],
//         lastname: fullName[1],
//         email: values.email,
//         phone: this.contact.phone_code + sanitizePhone(values.phone),
//         schedule: values.schedule,
//         triggeredBy: "tuu_popup_salida"
//     };

//     this.apiService.sendContactFormTUU(gSheetsBody).subscribe({
//         next: () => {
//             this.showMessage(
//                 '¡Solicitud creada! Será contactado en breve por nuestro departamento de ventas',
//                 'CERRAR',
//                 10000
//             );
//             this.formDirective.resetForm();
//             this.form.get('phone').setValue(EMPTY_PHONE_PLACEHOLDER);
//             this.activeCaptcha = false;
//             this.disableButton = false;
//             this.isReadOnly = false;
//             this.dialog.close();
//         },
//         error: (err) => {
//             this.showMessage(
//                 '¡Ups! Ocurrió un error al enviar sus datos de contacto',
//                 'REINTENTAR',
//                 0,
//                 true
//             );
//             this.isReadOnly = false;
//             this.disableButton = false;
//         },
//         complete: () => {
//             this.StartCaptcha();
//         }
//     });
// }
// setPhoneErrorMessage(): void {
//     this.phoneErrorMessage = getPhoneErrorMessage(this.form, this.contact.country_code);
// }
// phonePlaceholder(): string {
//     return getPhonePlaceholder(this.contact.country_code);
// }

// setEmailError(): void {
//     this.emailErrorMessage = getEmailErrorMessage(this.form.get('email'));
// }
// }
