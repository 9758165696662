import { Component } from '@angular/core';

@Component({
    selector: 'app-section-reservation-faq',
    templateUrl: './section-reservation-faq.component.html',
    styleUrls: ['./section-reservation-faq.component.scss']
})
export class SectionReservationFaqComponent {
    public activePanel = -1;

    public itemsFAQ = [
        {
            title: 'Ya tengo sitio web ¿Puedo integrarlo con TUU Reserva?',
            text: ['En caso que ya cuentes con un sitio web para tu negocio, podrás crear un botón o sección de reservas y vincularla directamente con la agenda online que crees en TUU Reserva.']
        }, {
            title: '¿Mi sitio de reservas funcionará en celulares y computadores?',
            text: ['Funcionará perfectamente en todos los dispositivos. Tu sitio web de reserva se adapta perfectamente a los diferentes tamaños de pantalla que existen para que tus clientes puedan agendar horas desde cualquier lugar.']
        }, {
            title: '¿Qué es una agenda?',
            text: [
                'Es una herramienta de la plataforma que permite organizar y planificar los horarios de los distintos recursos que posee tu negocio. Cada agenda está vinculada con un recurso, ya sean humanos o materiales.',
                'En el caso de recursos humanos podrán ser profesionales que prestan un servicio, como por ejemplo: un estilista en un salón de belleza, una masajista en un spa, un veterinario en una clínica veterinaria.', 'Por otra parte, serán recursos materiales, aquellos espacios o lugares destinados a prestar un servicio, como por ejemplo: una cancha de tenis en un centro deportivo, un quincho en un centro de eventos.'
            ]
        }, {
            title: '¿Debo comprar un POS con mi plan?',
            text: [
                'Si contratas el Plan Plus de Reserva no necesitas tener un POS y podrás probar gratis por 3 meses nuestro sistema.',
                'Si quieres usar gratuitamente por siempre el sistema con el Plan Básico tienes que contar con al menos una máquina POS TUU que uses activamente en tu negocio.'
            ]
        }, {
            title: '¿Cuánto debo pagar de comisión al recibir los pagos de mis clientes?',
            text: [
                'Lo único que tendrás que pagar es una comisión por transacción que va desde $65* + IVA. Dependiendo del plazo en el que elijas recibir tu dinero en tu cuenta bancaria, podría añadirse una comisión adicional de hasta un 0,79% + IVA por transacción. Esta tarifa aplica de manera uniforme para todo tipo de tarjetas.',
                'Puedes recibir abonos de tu dinero el día hábil siguiente eligiendo la comisión de 0,79% + $65* + IVA, o incluso disminuirla únicamente a $65* + IVA recibiendo tu dinero con un desfase de 25 días hábiles.',
                'Al seleccionar tu plan de Reserva te preguntaremos cuál comisión prefieres tener. Para conocer todas las comisiones visita la página principal de www.tuu.cl'
            ],
            disclaimer: '*Valor aproximado en CLP, equivalente a 0,00169 UF'
        }, {
            title: '¿Cuándo podré cobrarle a mis clientes?',
            text: [
                'Podrás elegir si prefieres cobrar anticipado a tus clientes al momento de agendar de forma online, o directamente en tu negocio cuando asistan a su cita.',
                'También puedes combinar ambas opciones dividiendo el pago. Para pagos presenciales prefiere cobrar con TUU POS para llevar registro de todos tus cobros en un mismo sistema.'
            ]
        }
    ];

    public openPanel(index): void {
        setTimeout(() => {
            this.activePanel = index;
        }, 0);

    }

}
