import { Component, AfterViewInit, HostListener, Input,Inject, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-section-how-to-request-mobile',
    templateUrl: './section-how-to-request-mobile.component.html',
    styleUrls: ['./section-how-to-request-mobile.component.scss']
})
export class SectionHowToRequestMobileComponent implements AfterViewInit {
    private transformStyleSubject = new BehaviorSubject<string>("");
    public transformStyle$ = this.transformStyleSubject.asObservable();
    @Input() public loadingImages: boolean;

    currentStep: number = 0;
    lineHeight: string = '105px';
    private userActivity: boolean = false;
    slideLeft: boolean = true;
    screenWidth: number = window.innerWidth; 
    private resizeTimeout;

    steps = [
        {
        title: 'Accede a la sección Abono Inmediato',
        description: 'Dentro de tu POS en la aplicación de Pagos, ingresa al menú lateral y selecciona "Abono Inmediato".',
        },
        {
        title: 'Solicitar Abono Inmediato',
        description: 'Verás tu saldo disponible para retiro, si aún no lo has solicitado, selecciona "Solicitar Abono".',
        },
        {
        title: 'Ingresa el monto y verifica los datos',
        description: 'Ingresa el monto que deseas solicitar, verifica los detalles y costos, y presiona solicitar para procesar el abono.',
        },
        {
        title: '¡Listo! Recibe tu Abono Inmediato',
        description: 'En menos de 15 minutos, los fondos se depositarán en la cuenta bancaria de tu comercio.',
        },
        {
        title: 'Verifica el estado de tu solicitud',
        description: 'En la sección de Abono Inmediato puedes ver el estado de tus solicitudes incluyendo monto, costos y estado del abono.',
        },
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.autoAdvance();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        clearTimeout(this.resizeTimeout); // Limpiar el timeout anterior
        this.resizeTimeout = setTimeout(() => {
            this.screenWidth = event.target.innerWidth;
            this.translateValue();
        }, 200);
    }

    public stepForward(){
        this.userActivity = true;
        if(this.currentStep < 4){
            this.slideLeft = false;
            this.currentStep++;
            this.translateValue();
        }
    }

    public stepBack(){
        this.userActivity = true;
        if(this.currentStep > 0){
            this.slideLeft = true;
            this.currentStep--;
            this.translateValue();
        }
    }

    public translateValue(): void {
        const stepWidth = this.screenWidth <= 600 ? 360 : 600;  // Usamos 360px si el ancho es <= 600px
        this.transformStyleSubject.next(`translateX(${-(this.currentStep * stepWidth)}px)`);  // Calculamos el desplazamiento
    }

    private autoAdvance(){
        setTimeout(() => {
            if(!this.userActivity){
                this.currentStep = (this.currentStep + 1) % this.steps.length;
                this.translateValue();
                this.autoAdvance();
            }
        }, 5000);
    }
}